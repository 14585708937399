import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import axios from "axios";
import Modal from "../../components/Orders/Modal";
import HistoryModal from "../../components/Orders/Modal2";
import { Spinner } from "reactstrap";
import ColumnFilter from "../../components/Orders/ModalColumn";

function calLastUpdateTime(utcTime) {
  let result;
  if (utcTime !== null && utcTime !== "" && utcTime !== undefined) {
    let now = new Date();
    let endDate = new Date(utcTime);
    let diff = now - endDate;
    let hours = Math.floor(diff / 3.6e6);
    let minutes = Math.floor((diff % 3.6e6) / 6e4);
    if (hours < 24) {
      if (hours > 0) {
        result = hours + " hour, " + minutes + " minutes " + "ago";
      } else {
        result = minutes + " minutes " + "ago";
      }
    } else if (hours >= 24) {
      let Difference_In_Time = now.getTime() - endDate.getTime();
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      let TotalDays = Difference_In_Days.toString().split(".")[0];
      result = TotalDays + " Days";
    } else {
      result = hours + " hour, " + minutes + " minutes " + "ago";
    }

    return result;
  }
}

function getStatusval(statusval) {
  if (statusval !== null && statusval !== "" && statusval !== undefined) {
    let statusName;
    statusName = statusval.split(" ").join("");
    try {
      switch (statusval) {
        case "Cancelled":
          statusName = "-1";
          break;
        case "Generated":
          statusName = "1";
          break;
        case "Driver Assigned":
          statusName = "2";
          break;
        case "On Route To Terminal":
          statusName = "3";
          break;
        case "Loading Started":
          statusName = "4";
          break;
        case "On Route To Delivery":
          statusName = "5";
          break;
        case "Arrived At Station":
          statusName = "6";
          break;
        case "Completed":
          statusName = "7";
          break;
      }
    } catch (err) {
      console.log(err.message);
    }
    return statusName;
  }
}

function AddStatusIcon(inputval) {
  const StatusColorPoint = [];
  if (inputval !== "" && inputval !== null && inputval !== undefined) {
    try {
      if (inputval === "-1" || inputval === -1) {
        StatusColorPoint.push(
          <Icon.CircleFill className="Statusiconmargin" color="red" size={10} />
        );
      } else if (inputval === "-2" || inputval === -2) {
        StatusColorPoint.push(
          <Icon.CircleFill className="Statusiconmargin" color="red" size={10} />
        );
      } else if (inputval === "1" || inputval === 1) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval === "2" || inputval === 2) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval === "3" || inputval === 2) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval === "4" || inputval === 4) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval === "5" || inputval === 5) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval === "6" || inputval === 6) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="aqua"
            size={10}
          />
        );
      } else if (inputval === "7" || inputval === 7) {
        StatusColorPoint.push(
          <Icon.CircleFill
            className="Statusiconmargin"
            color="lightgreen"
            size={10}
          />
        );
      }
    } catch (err) {
      console.log(err.message);
    }
    return StatusColorPoint;
  }
}

const OrderTable = (props) => {
  const [currentUser, setCurrentUser] = useState(false);
  const [show, setShow] = useState(false);
  const [modalshow, setShowmodal] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [data, setData] = useState([]);
  const [post, setPost] = useState(null);
  const [count, setCount] = useState(0);
  const [url, setUrl] = useState(process.env.REACT_APP_URL);
  const [isLoading, setIsLoading] = useState(false);
  const [showPrev, setShowPrev] = useState(true);
  const [showNext, setShowNext] = useState(true);
  const [nextkeyval, setNextKeyval] = useState(null);
  const [filterUrl, setfilterUrl] = useState();
  const [stackCount, setStackCount] = useState(0);
  const [stack, setStack] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(null);
  const [totalDataCountcheck, setTotalDataCountcheck] = useState(null);
  const [dataCount, setDataCount] = useState(null);
  const [isWestcoast, setIswestcoast] = useState(false);
  const [columnFiltershow, setShowColumnFilter] = useState(false);
  const [userSettingData, setSettingData] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setfilterUrl(url + "/api/orders?");
    setIsLoading(true);
    axios
      .get(url)
      .then(function (res) {
        setTimeout(function () {
          setData(res.data);
          setMasterData(res.data);
          setCurrentUser(res.data);
          setIsLoading(false);
        }, 500);
        setTotalDataCount(res.headers.totalrecords);
        setTotalDataCountcheck(res.headers.totalrecords);
        //setIswestcoast(res.headers.iswestcoast);
        setPost(res);
        binddatacount(res.headers.totalrecords);
        if (res.headers.nextkey !== null && res.headers.nextkey !== "") {
          setShowNext(false);
          setNextKeyval(res.headers.nextkey);
        } else {
          setShowNext(true);
        }
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.location = "/Login?error=" + err.response.data.message;
        } else if (err.response.status === 403) {
          window.location =
            "/Login?error=" + "Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error=" + err.message;
        }
      })
      .finally(() => {
        setTimeout(function () {
          setIsLoading(false);
        }, 500);
      });

    axios
      .get(url + "/api/getusersettings")
      .then(function (res) {
        setSettingData(res.data);
        // localStorage.setItem("sessioncheckedSettings", res.data.resultSettings);
      })
      .catch(function (err) {
        if (err.response.status === 401) {
          window.location = "/Login?error=" + err.response.data.message;
        } else if (err.response.status === 403) {
          window.location =
            "/Login?error=" + "Your token is expired, please login from bpc.";
        } else {
          window.location = "/Error?error=" + err.message;
        }
      });
  }, []);

  useEffect(() => {
    if (count == 0) {
      bindSelectedItems();
      checkbtnPrevstatus();
    }
  });

  function binddatacount(param, page) {
    let orderList = localStorage.getItem("sessionOrderPagePrevKey");
    if (parseInt(param) == "0") {
      setDataCount("0");
    } else if (parseInt(param) <= 1000 && orderList !== null && page === 1) {
      setDataCount("1 - " + param);
    } else if (parseInt(param) <= 1000 && orderList === null) {
      setDataCount("1 - " + param);
    } else if (orderList !== null && orderList !== "") {
      let str_array = orderList.split(",");
      let listlength = str_array.length;
      if (listlength > 0) {
        let endcount = listlength + 1;
        let endNum = endcount + "000";
        if (parseInt(endNum) < parseInt(param)) {
          setDataCount(listlength + "001" + " - " + endcount + "000");
        } else {
          setDataCount(listlength + "001" + " - " + param);
        }
      }
    } else if (parseInt(param) === 1000 && page === undefined) {
      setDataCount("1 - " + param);
    } else if (parseInt(param) > 1000) {
      setDataCount("1 - 1000");
    }
  }
  function checkbtnPrevstatus() {
    try {
      let prevKeyVal = localStorage.getItem("sessionOrderPagePrevKey");
      if (prevKeyVal !== null && prevKeyVal !== "") {
        let getvalist = prevKeyVal.split(",");
        setStack(getvalist);
        setShowPrev(false);
      } else {
        setShowPrev(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function getStatusName(statusval) {
    if (statusval !== null && statusval !== "" && statusval !== undefined) {
      let statusName;
      try {
        switch (statusval) {
          case -1:
            statusName = "Cancelled";
            break;
          case -2:
            statusName = "Expired";
            break;
          case 1:
            statusName = "Generated";
            break;
          case 2:
            statusName = "Driver Assigned";
            break;
          case 3:
            statusName = "On Route To Terminal";
            break;
          case 4:
            statusName = "Loading Started";
            break;
          case 5:
            statusName = "On Route To Delivery";
            break;
          case 6:
            statusName = "Arrived At Station";
            break;
          case 7:
            statusName = "Completed";
            break;
        }
      } catch (err) {
        console.log(err.message);
      }
      return statusName;
    }
  }

  function calculateETAbyTimezone(utcEDate, utcLDate, tzString) {
    try {
      let checkEdateYear = utcEDate.split("-");
      let checkLdateYear = utcLDate.split("-");
      if (
        utcEDate !== null &&
        utcEDate !== "" &&
        utcEDate !== undefined &&
        utcLDate !== null &&
        utcLDate !== "" &&
        utcLDate !== undefined &&
        tzString !== null &&
        tzString !== "" &&
        tzString !== undefined &&
        checkEdateYear[0] !== undefined &&
        checkEdateYear[0] !== "0001" &&
        checkEdateYear[0] !== "" &&
        checkLdateYear[0] !== undefined &&
        checkLdateYear[0] !== "0001" &&
        checkLdateYear[0] !== ""
      ) {
        const options = {
          timeZone: tzString,
          timeZoneName: "short",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
        };
        const Edate = new Date(utcEDate);
        const Ldate = new Date(utcLDate);
        const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Edate
        );
        const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Ldate
        );
        return Eformatter + " - " + Lformatter;
      }
      else {
        return "No data";
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function findArconumber(SiteName) {
    let lastChar = SiteName.substr(SiteName.length - 1);
    if (lastChar.length <= 1) {
      if (!isNaN(lastChar)) {
        return SiteName.split("-").pop();
      } else {
        return "No data";
      }
    }
  }

  function calculateETAbyTimezoneformobile(EarliestDate, LatestDate, tzString) {
    try {
      let chkEdateYear = EarliestDate.split("-");
      let chkLdateYear = LatestDate.split("-");
      if (
        EarliestDate !== null &&
        LatestDate !== null &&
        tzString !== null &&
        EarliestDate !== "" &&
        LatestDate !== "" &&
        tzString !== "" &&
        EarliestDate !== undefined &&
        LatestDate !== undefined &&
        tzString !== undefined &&
        chkEdateYear[0] !== undefined &&
        chkEdateYear[0] !== "0001" &&
        chkEdateYear[0] !== "" &&
        chkLdateYear[0] !== undefined &&
        chkLdateYear[0] !== "0001" &&
        chkLdateYear[0] !== ""
      ) {
        const options = {
          timeZone: tzString,
          timeZoneName: "short",
          hour: "numeric",
          minute: "numeric",
        };
        const Edate = new Date(EarliestDate);
        const Ldate = new Date(LatestDate);
        const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Edate
        );
        const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Ldate
        );
        return Eformatter + " - " + Lformatter;
      }
      else {
        return "No data";
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function ShowDateforEtamobile(EarliestDate, LatestDate, tzString) {
    try {
      let chkEYear = EarliestDate.split("-");
      let chkLYear = LatestDate.split("-");
      if (
        EarliestDate !== null &&
        LatestDate !== null &&
        tzString !== null &&
        EarliestDate !== "" &&
        LatestDate !== "" &&
        tzString !== "" &&
        EarliestDate !== undefined &&
        LatestDate !== undefined &&
        tzString !== undefined &&
        chkEYear[0] !== undefined &&
        chkEYear[0] !== "0001" &&
        chkEYear[0] !== "" &&
        chkLYear[0] !== undefined &&
        chkLYear[0] !== "0001" &&
        chkLYear[0] !== ""
      ) {
        const options = {
          timeZone: tzString,
          day: "2-digit",
          month: "short",
        };
        const Edate = new Date(EarliestDate);
        const Ldate = new Date(LatestDate);
        const Eformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Edate
        );
        const Lformatter = new Intl.DateTimeFormat("en-UA", options).format(
          Ldate
        );
        return Eformatter + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" + Lformatter;
      }
      else {
        return "";
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  function renderOrders() {
    const OrdersList = [];
    if (data.length > 0 && data !== "nodata" && data.length !== 1210) {
      try {
        for (let i = 0; i < data.length; i++) {
          OrdersList.push(
            <tr className="trDays">
              <td colSpan={7} className="tdfiltercheck">
                {" "}
                <span className="divDays">
                  {myDate(data[i].date + " 00:00:00")}
                </span>
                <span className="divdate">
                  {changeDateFormat(data[i].date + " 00:00:00")}
                </span>
              </td>
            </tr>
          );
          if (
            data[i].orders.length > 0 &&
            data[i].orders !== undefined && isNaN(data[i].orders )
          ) {
            for (let j = 0; j < data[i].orders.length; j++) {
              OrdersList.push(
                <tr
                  className="tddiv"
                  onClick={() =>
                    navigate("/Orderdetails", {
                      state: {
                        id: data[i].orders[j].OrderId,
                        lastupdate: calLastUpdateTime(
                          data[i].orders[j].UpdateDateTimeUTC
                        ),
                        SiteId: data[i].orders[j].SiteId,
                        GpsAvailable: data[i].orders[j].GpsAvailable,
                      },
                    })
                  }
                >
                  <td className="tdbold Dest_col">
                    <span className="box"></span>{" "}
                    {data[i].orders[j].Destination} - {data[i].orders[j].SiteId}
                    {/* {isWestcoast === "true"
                      ? data[i].orders[j].Destination
                      : data[i].orders[j].Destination -
                        data[i].orders[j].SiteId}*/}
                  </td>
                  {/* {isWestcoast === "true" ? (
                    <td className="sidenumberwidth SiteNumber_col">
                      {data[i].orders[j].SiteId}
                    </td>
                  ) : (
                    ""
                  )}*/}
                  <td className="tdwidth Eta_col">
                    {/* {
                      calETA(
                        data[i].orders[j].EarliestDateTimeUTC,
                        data[i].orders[j].LatestDateTimeUTC
                      )
                      // etaDate(
                      //   data[i].orders[j].EarliestDateTimeUTC,
                      //   data[i].orders[j].LatestDateTimeUTC
                      // )
                    } */}
                    {data[i].orders[j].EarliestDateTimeUTC
                      ? calculateETAbyTimezone(
                          data[i].orders[j].EarliestDateTimeUTC,
                          data[i].orders[j].LatestDateTimeUTC,
                          data[i].orders[j].TimeZone
                        )
                      : "No data"}
                  </td>
                  <td className="tdwidth Carrier_col">
                    {data[i].orders[j].Carrier
                      ? data[i].orders[j].Carrier
                      : "No data"}
                  </td>
                  <td className="SiteName_col">
                    {data[i].orders[j].SiteName
                      ? data[i].orders[j].SiteName
                      : "No data"}
                  </td>
                  {isWestcoast === "true" ? (
                    <td className="ArcoNumber_col">
                      {data[i].orders[j].SiteName
                        ? findArconumber(data[i].orders[j].SiteName)
                        : "No data"}
                    </td>
                  ) : (
                    ""
                  )}

                  {data[i].orders[j].GpsAvailable === true ? (
                    <td className="tdwidthorder Order_col">
                      {data[i].orders[j].OrderId}
                    </td>
                  ) : (
                    <td className="tdwidthorder clrAmber Order_col">
                      {data[i].orders[j].OrderId}
                    </td>
                  )}

                  <td className="tdwidthstatus Status_col">
                    {AddStatusIcon(data[i].orders[j].OrderStatus)}
                    {getStatusName(data[i].orders[j].OrderStatus)}
                  </td>
                  <td className="tdwidth Updated_col">
                    {calLastUpdateTime(data[i].orders[j].UpdateDateTimeUTC)}
                  </td>
                  <td className="arrright">
                    {data[i].orders[j].GpsAvailable === true ? (
                      <Icon.ArrowRight />
                    ) : (
                      <Icon.ExclamationTriangleFill
                        title="No GPS"
                        color="#FFBF00"
                      />
                    )}
                  </td>
                </tr>
              );
            }
          } else {
            OrdersList.push(
              <tr className="trDays">
                <td colSpan={7}>
                  <span className="box"></span>No Orders
                </td>
              </tr>
            );
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    } else if (data.length == 1210) {
      //Not required to implement here
    } else {
      OrdersList.push(
        <tr className="trDays">
          <td colSpan={7}>
            <span className="box"></span>No Orders
          </td>
        </tr>
      );
    }
    return OrdersList;
  }

  function renderOrdersformobile() {
    const OrdersListformobile = [];
    if (
      data.length > 0 &&
      data !== "nodata" &&
      data !== undefined &&
      data.length !== 1210 &&
      isNaN(data)
    ) {
      try {
        for (let i = 0; i < data.length; i++) {
          OrdersListformobile.push(
            <div className="col coldays">
              <span className="divDays">
                {myDate(data[i].date + " 00:00:00")}
              </span>
              <span className="divdate">
                {changeDateFormat(data[i].date + " 00:00:00")}
              </span>
            </div>
          );
          if (data[i].orders.length > 0) {
            for (let j = 0; j < data[i].orders.length; j++) {
              OrdersListformobile.push(
                <div
                  class="card"
                  onClick={() =>
                    navigate("/Orderdetails", {
                      state: {
                        id: data[i].orders[j].OrderId,
                        lastupdate: calLastUpdateTime(
                          data[i].orders[j].UpdateDateTimeUTC
                        ),
                        SiteId: data[i].orders[j].SiteId,
                        GpsAvailable: data[i].orders[j].GpsAvailable,
                      },
                    })
                  }
                >
                  <div class="card-body">
                    <div className="row">
                      <div className="col alignrightforupdatetime">
                        {calLastUpdateTime(data[i].orders[j].UpdateDateTimeUTC)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col tdbold marginbtm">
                        <Icon.GeoAlt />{" "}
                        {isWestcoast === "true"
                          ? data[i].orders[j].Destination
                          : data[i].orders[j].Destination +
                            "-" +
                            data[i].orders[j].SiteId}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        <Icon.Clock />{" "}
                        {data[i].orders[j].EarliestDateTimeUTC
                          ? calculateETAbyTimezoneformobile(
                              data[i].orders[j].EarliestDateTimeUTC,
                              data[i].orders[j].LatestDateTimeUTC,
                              data[i].orders[j].TimeZone
                            )
                          : "No data"}
                        <p className="petaorder">
                          {data[i].orders[j].EarliestDateTimeUTC
                            ? ShowDateforEtamobile(
                                data[i].orders[j].EarliestDateTimeUTC,
                                data[i].orders[j].LatestDateTimeUTC,
                                data[i].orders[j].TimeZone
                              )
                            : ""}
                        </p>
                      </div>
                      <div className="col">
                        <Icon.Truck />{" "}
                        {data[i].orders[j].Carrier
                          ? data[i].orders[j].Carrier
                          : "No data"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {" "}
                        <Icon.CardList />{" "}
                        {data[i].orders[j].GpsAvailable === true ? (
                          data[i].orders[j].OrderId
                        ) : (
                          <span className="clrAmber">
                            {data[i].orders[j].OrderId}
                          </span>
                        )}
                      </div>
                      <div className="col">
                        {AddStatusIcon(data[i].orders[j].OrderStatus)}{" "}
                        {getStatusName(data[i].orders[j].OrderStatus)}
                      </div>
                    </div>
                    {isWestcoast === "true" ? (
                      <div className="row paddingtopsitename">
                        <div className="col">
                          <Icon.Truck /> {data[i].orders[j].SiteId}
                        </div>
                        <div className="col">
                          <Icon.GeoAlt />{" "}
                          {data[i].orders[j].SiteName
                            ? findArconumber(data[i].orders[j].SiteName)
                            : "No data"}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="col paddingtopsitename">
                        <Icon.GeoAlt />
                        {data[i].orders[j].SiteName
                          ? data[i].orders[j].SiteName
                          : "No data"}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col alignright">
                        {" "}
                        {data[i].orders[j].GpsAvailable === true ? (
                          <Icon.ArrowRight />
                        ) : (
                          <Icon.ExclamationTriangleFill
                            title="No GPS"
                            color="#FFBF00"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    } else if(data.length === 1210)
      {
        //not required to implement here
      }
        else {
      OrdersListformobile.push(<span className="box">No Orders</span>);
    }
    return OrdersListformobile;
  }
  function myDate(date) {
    if (date !== null && date !== "" && date !== "undefined 00:00:00") {
      try {
        let r = "";
        let inputDate = new Date(date);
        let todayDate = new Date();
        let tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        let yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        let weekdays = new Array(7);
        weekdays[0] = "SUNDAY";
        weekdays[1] = "MONDAY";
        weekdays[2] = "TUESDAY";
        weekdays[3] = "WEDNESDAY";
        weekdays[4] = "THURSDAY";
        weekdays[5] = "FRIDAY";
        weekdays[6] = "SATURDAY";
        if (
          inputDate.setHours(0, 0, 0, 0) == yesterdayDate.setHours(0, 0, 0, 0)
        ) {
          r = "YESTERDAY";
        } else if (
          inputDate.setHours(0, 0, 0, 0) == todayDate.setHours(0, 0, 0, 0)
        ) {
          r = "TODAY";
        } else if (
          inputDate.setHours(0, 0, 0, 0) == tomorrowDate.setHours(0, 0, 0, 0)
        ) {
          r = "TOMORROW";
        } else {
          r = weekdays[inputDate.getDay()];
        }
        return r;
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  function changeDateFormat(utcdate) {
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (utcdate !== "" && utcdate !== null && utcdate !== undefined) {
      const Ldate = new Date(utcdate);

      let monthname = month[Ldate.getMonth()];

      let Dateval = Ldate.getDate();

      let yearVal = Ldate.getFullYear();

      return monthname + " " + Dateval + ", " + yearVal;
    }
  }

  const bindSelectedItems = () => {
    try {
      //document.getElementById("txtsearchm").value = " ";
      //document.getElementById("txtsearch").value = " ";
      setIsLoading(true);
      let getfiltercountval = localStorage.getItem("sessionapplyfiltercount");
      if (getfiltercountval > 1) {
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        setStack([]);
      }
      let getDestinationselectedValue =
        localStorage.getItem("sessionresultDest");
      if (
        getDestinationselectedValue !== null &&
        getDestinationselectedValue !== ""
      ) {
        var filterval = getDestinationselectedValue.split(",");
        filterval.pop();
      }
      let getStatusselectedValue = localStorage.getItem("sessionresultStatus");
      if (getStatusselectedValue !== null && getStatusselectedValue !== "") {
        var filterStatusval = getStatusselectedValue.split(",");
        filterStatusval.pop();
      }

      let getHistoryselectedValue = localStorage.getItem("sessionHistory");
      if (getHistoryselectedValue !== null && getHistoryselectedValue !== "") {
        var filterHistoryval = getHistoryselectedValue.split(",");
        filterHistoryval.pop();
      }
      //Call Api to get the filter data
      let sDestination = "",
        sStatusVal = "",
        sHistory = "";
      if (
        getDestinationselectedValue !== undefined &&
        getDestinationselectedValue !== null
      ) {
        sDestination = getDestinationselectedValue.slice(0, -1);
      }
      if (
        getStatusselectedValue !== undefined &&
        getStatusselectedValue !== null
      ) {
        let sStatusv = getStatusselectedValue.split(",");
        sStatusv.splice(-1);
        if (sStatusv.length > 0) {
          for (var j = 0; j < sStatusv.length; j++) {
            sStatusVal += getStatusval(sStatusv[j]);
            sStatusVal += ",";
          }
          sStatusVal = sStatusVal.replace(/,\s*$/, "");
        }
      }
      if (
        getHistoryselectedValue !== undefined &&
        getHistoryselectedValue !== null
      ) {
        sHistory = getHistoryselectedValue.slice(0, -1);
      }
      let apiurl = url + "/api/orders?";
      let count = 0;
      if (sDestination !== undefined && sDestination !== "") {
        apiurl += "Location=" + sDestination;
        count++;
      }
      if (sStatusVal !== undefined && sStatusVal !== "") {
        if (count == 0) {
          apiurl += "OrderStatus=" + sStatusVal;
          count++;
        } else {
          apiurl += "&OrderStatus=" + sStatusVal;
        }
      }
      if (sHistory !== undefined && sHistory !== "") {
        if (sHistory !== "Custom") {
          let datevl = getHistoryDate(sHistory);
          if (count == 0) {
            apiurl += "FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          } else {
            apiurl += "&FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          }
        } else {
          let CustomDateValue = localStorage.getItem("sessionCustomHistory");
          if (CustomDateValue !== null) {
            let CustomDateRangeval = CustomDateValue.split("-");
            if (count == 0) {
              apiurl +=
                "FromDate=" +
                CustomDateRangeval[0] +
                "-" +
                CustomDateRangeval[1] +
                "-" +
                CustomDateRangeval[2] +
                "&ToDate=" +
                CustomDateRangeval[3].replace(/\s+/g, "") +
                "-" +
                CustomDateRangeval[4] +
                "-" +
                CustomDateRangeval[5];
            } else {
              apiurl +=
                "&FromDate=" +
                CustomDateRangeval[0] +
                "-" +
                CustomDateRangeval[1] +
                "-" +
                CustomDateRangeval[2] +
                "&ToDate=" +
                CustomDateRangeval[3].replace(/\s+/g, "") +
                "-" +
                CustomDateRangeval[4] +
                "-" +
                CustomDateRangeval[5];
            }
          }
          let pageKey = localStorage.getItem("sessionOrderPageKey");
          if (pageKey !== null) {
            apiurl += "&nextkey=" + pageKey;
          }
        }
      }
      let divId = document.getElementById("divsetdestname");
      if (divId !== null && divId !== undefined) {
        setCount(1);
        setfilterUrl(apiurl);
        localStorage.setItem("sessionfilterurl", apiurl);
        axios
          .get(apiurl)
          .then(function (res) {
            setData(res.data);
            setMasterData(res.data);
            setPost(res.data);
            setIsLoading(false);
            // renderOrders();
            // renderOrdersformobile();
            setTotalDataCount(res.headers.totalrecords);
            setTotalDataCountcheck(res.headers.totalrecords);
            binddatacount(res.headers.totalrecords);
            // setIswestcoast(res.headers.iswestcoast);
            if (res.headers.nextkey !== null && res.headers.nextkey !== "") {
              setShowNext(false);
              setNextKeyval(res.headers.nextkey);
            } else {
              setShowNext(true);
            }
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              window.location = "/Login?error=" + err.response.data.message;
            } else if (err.response.status === 403) {
              window.location =
                "/Login?error=" +
                "Your token is expired, please login from bpc.";
            } else {
              window.location = "/Error?error=" + err.message;
            }
          });
        document.getElementById("divsetdestname").innerHTML = "";
        if (filterval !== undefined && filterval.length > 0) {
          for (var j = 0; j < filterval.length; j++) {
            let element = document.createElement("button");
            let selected = filterval[j];
            element.type = "button";
            element.id = filterval[j];
            element.value = filterval[j];
            element.name = "Location";
            element.className = "btn btn-secondary btn-sm";
            element.style =
              "border-radius:12px; margin-right:10px;margin-bottom: 10px;";
            element.innerHTML = filterval[j];
            let spantag = document.createElement("span");
            spantag.innerHTML = '<span aria-hidden="true"> &times;</span>';
            spantag.onclick = function () {
              CloseSelectedButton(selected, element.name);
            };
            spantag.style = "cursor: pointer";
            element.appendChild(spantag);
            divId.appendChild(element);
          }
        }
        if (filterStatusval !== undefined && filterStatusval.length > 0) {
          for (var k = 0; k < filterStatusval.length; k++) {
            let btnStatus = document.createElement("button");
            let selected = filterStatusval[k];
            btnStatus.type = "button";
            btnStatus.id = filterStatusval[k];
            btnStatus.value = filterStatusval[k];
            btnStatus.name = "Status";
            btnStatus.className = "btn btn-secondary btn-sm";
            btnStatus.style =
              "border-radius:12px; margin-right:10px;margin-bottom: 10px;";
            btnStatus.innerHTML = filterStatusval[k];
            let spantag = document.createElement("span");
            spantag.innerHTML = '<span aria-hidden="true"> &times;</span>';
            spantag.onclick = function () {
              CloseSelectedButton(selected, btnStatus.name);
            };
            spantag.style = "cursor: pointer";
            btnStatus.appendChild(spantag);

            divId.appendChild(btnStatus);
          }
        }

        if (filterHistoryval !== undefined && filterHistoryval.length > 0) {
          for (var p = 0; p < filterHistoryval.length; p++) {
            let btnHistory = document.createElement("button");
            let selected = filterHistoryval[p];
            btnHistory.type = "button";
            btnHistory.id = filterHistoryval[p];
            btnHistory.value = filterHistoryval[p];
            btnHistory.name = "History";
            btnHistory.className = "btn btn-secondary btn-sm";
            btnHistory.style =
              "border-radius:12px; margin-right:10px;margin-bottom: 10px;";
            btnHistory.innerHTML = filterHistoryval[p];
            let spantag = document.createElement("span");
            spantag.innerHTML = '<span aria-hidden="true"> &times;</span>';
            spantag.onclick = function () {
              CloseSelectedButton(selected, btnHistory.name);
            };
            spantag.style = "cursor: pointer";
            btnHistory.appendChild(spantag);

            divId.appendChild(btnHistory);
          }
        }
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      checkbtnPrevstatus();
      let chktxtsearchval = localStorage.getItem("sessiontxtsearchval");
      if (
        chktxtsearchval !== null &&
        chktxtsearchval !== "" &&
        chktxtsearchval !== undefined
      ) {
        handletextChange();
      }
    }
  };
  const CloseSelectedButton = (Id, name) => {
    try {
      setIsLoading(true);
      let btnremove = document.getElementById(Id);
      btnremove.remove();
      let resultDest = [];
      let resultStatus = [];
      if (name == "History") {
        localStorage.removeItem("sessionHistory");
        localStorage.removeItem("sessionCustomHistory");
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionapplyfiltercount");
        setStack([]);
      }
      if (name == "Location") {
        const selectedValue = localStorage.getItem("sessionresultDest");
        let filterval = selectedValue.split(",");
        filterval.splice(-1);
        if (filterval.length > 0) {
          for (var i = 0; i < filterval.length; i++) {
            if (filterval[i] !== Id) {
              resultDest += filterval[i] + ",";
            }
          }
        }
        localStorage.removeItem("sessionresultDest");
        localStorage.setItem("sessionresultDest", resultDest);
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionapplyfiltercount");
        setStack([]);
      }
      if (name == "Status") {
        const selectedStatus = localStorage.getItem("sessionresultStatus");
        let filterStatus = selectedStatus.split(",");
        filterStatus.splice(-1);
        if (filterStatus.length > 0) {
          for (var j = 0; j < filterStatus.length; j++) {
            if (filterStatus[j] !== Id) {
              resultStatus += filterStatus[j] + ",";
            }
          }
        }
        localStorage.removeItem("sessionresultStatus");
        localStorage.setItem("sessionresultStatus", resultStatus);
        localStorage.removeItem("sessionOrderPagePrevKey");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionapplyfiltercount");
        setStack([]);
      }

      let urlOrder = url + "/api/orders?";
      let selecteddest = localStorage.getItem("sessionresultDest");
      let selectOrderStatus = localStorage.getItem("sessionresultStatus");
      let selectedHistory = localStorage.getItem("sessionHistory");
      if (
        selecteddest !== undefined &&
        selecteddest !== null &&
        selecteddest !== ""
      ) {
        var sDestremoved = selecteddest.slice(0, -1);
      }
      if (
        selectOrderStatus !== undefined &&
        selectOrderStatus !== null &&
        selectOrderStatus !== ""
      ) {
        var sStatusremoved = [];
        let selectedStatusafterRemoved = selectOrderStatus.slice(0, -1);
        if (selectedStatusafterRemoved !== null) {
          let sremovedStatus = selectedStatusafterRemoved.split(",");
          for (var j = 0; j < sremovedStatus.length; j++) {
            sStatusremoved += getStatusval(sremovedStatus[j]) + ",";
          }
          sStatusremoved = sStatusremoved.replace(/,\s*$/, "");
        }
      }
      if (
        selectedHistory !== undefined &&
        selectedHistory !== null &&
        selectedHistory !== ""
      ) {
      }
      let countr = 0;
      if (sDestremoved !== undefined && sDestremoved !== "") {
        urlOrder += "Location=" + sDestremoved;
        countr++;
      }
      if (sStatusremoved !== undefined && sStatusremoved !== "") {
        if (countr == 0) {
          urlOrder += "OrderStatus=" + sStatusremoved;
          countr++;
        } else {
          urlOrder += "&OrderStatus=" + sStatusremoved;
        }
      }
      if (selectedHistory !== undefined && selectedHistory !== "" && selectedHistory !== null) {
        if (selectedHistory !== "Custom,") {
          let Historytext = selectedHistory.slice(0, -1);
          let datevl = getHistoryDate(Historytext);
          if (countr == 0) {
            urlOrder += "FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          } else {
            urlOrder += "&FromDate=" + datevl[0] + "&ToDate=" + datevl[1];
          }
        } else {
          let CustomDateVal = localStorage.getItem("sessionCustomHistory");
          let CustomDateRangeval = CustomDateVal.split("-");
          if (CustomDateRangeval !== null) {
            urlOrder +=
              "FromDate=" +
              CustomDateRangeval[0] +
              "-" +
              CustomDateRangeval[1] +
              "-" +
              CustomDateRangeval[2] +
              "&ToDate=" +
              CustomDateRangeval[3].replace(/\s+/g, "") +
              "-" +
              CustomDateRangeval[4] +
              "-" +
              CustomDateRangeval[5];
          }
          if (countr == 0) {
            //   url += "FromDate=" + "" + "&ToDate=" + "";
            // } else {
            //   url += "&FromDate=" + "" + "&ToDate=" + "";
            // Not required to pass any parameter in url.
          }
        }
      }
      setfilterUrl(urlOrder);
      localStorage.setItem("sessionfilterurl", urlOrder);
      axios
        .get(urlOrder)
        .then(function (res) {
          setData(res.data);
          setMasterData(res.data);
          setPost(res.data);
          setTotalDataCount(res.headers.totalrecords);
          setTotalDataCountcheck(res.headers.totalrecords);
          binddatacount(res.headers.totalrecords);
          //setIswestcoast(res.headers.iswestcoast);
          if (res.headers.nextkey != null && res.headers.nextkey != "") {
            setShowNext(false);
            setNextKeyval(res.headers.nextkey);
          } else {
            setShowNext(true);
          }
          setShowPrev(true);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    } catch (err) {
      console.log(err.message);
    } finally {
      let chksessionval = localStorage.getItem("sessiontxtsearchval");
      if (
        chksessionval !== null &&
        chksessionval !== "" &&
        chksessionval !== undefined
      ) {
        handletextChange();
      }
      setTimeout(function () {
        setIsLoading(false);
      }, 1000);
    }
  };
  function getHistoryDate(Datetext) {
    let FromDate = "";
    let ToDate = "";
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    try {
      if (Datetext == "Today") {
        FromDate = today;
        ToDate = today;
      } else if (Datetext == "Yesterday") {
        FromDate = GetPreviousDate(-1);
        ToDate = GetPreviousDate(-1);
      } else if (Datetext == "Last 7 Days") {
        FromDate = GetPreviousDate(-7);
        ToDate = today;
      } else if (Datetext == "Last 30 Days") {
        FromDate = GetPreviousDate(-30);
        ToDate = today;
      }
    } catch (err) {
      console.log(err.message);
    }

    return [FromDate, ToDate];
  }
  function GetPreviousDate(prvalue) {
    let prdate = "";
    try {
      if (prvalue !=="" && prvalue !== null) {
        let d = new Date();
        if (prvalue == "-1") {
          d.setDate(d.getDate() - 1);
          prdate = FormatDate(d);
        } else if (prvalue == "-7") {
          d.setDate(d.getDate() - 7);
          prdate = FormatDate(d);
        } else if (prvalue == "-30") {
          d.setDate(d.getDate() - 30);
          prdate = FormatDate(d);
        }
        return prdate;
      }
    } catch (err) {
      console.log(err.message);
    }
  }
  function FormatDate(date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = yyyy + "-" + mm + "-" + dd;
    return date;
  }
  // Removed UI side Search textbox Filter
  // function handletextChange(event) {
  //   try {
  //     setIsLoading(true);
  //     let textvalue;
  //     let filteredData = [];
  //     if (event != undefined) {
  //       textvalue = event.target.value;
  //       textvalue = textvalue.toUpperCase();
  //     } else {
  //       textvalue = document.getElementById("txtsearchm").value;
  //       textvalue = textvalue.toUpperCase();
  //     }
  //     let countdata = 0;
  //     if (textvalue != "" && textvalue != null && textvalue != undefined) {
  //       if (masterData.length > 0) {
  //         masterData.forEach((element) => {
  //           let filterdaysOrders = element.orders.filter(
  //             (x) =>
  //               x.Destination?.toUpperCase().includes(textvalue) ||
  //               x.OrderId?.toString().includes(textvalue) ||
  //               x.Carrier?.toUpperCase().includes(textvalue) ||
  //               x.SiteName?.toUpperCase().includes(textvalue) ||
  //               x.SiteId?.toString().includes(textvalue)
  //           );
  //           if (filterdaysOrders.length > 0) {
  //             filteredData.push({
  //               date: element.date,
  //               orders: filterdaysOrders,
  //             });
  //           }
  //         });
  //       }
  //       setData(filteredData);
  //       if(filteredData.length > 0)
  //       {
  //          for(var i = 0; i < filteredData.length; i++)
  //          {
  //             countdata = countdata + filteredData[i].orders.length;
  //          }
  //       }
  //       setTotalDataCount(countdata)
  //       binddatacount(countdata, 1)
  //       // renderOrders();
  //       // renderOrdersformobile();
  //     } else {
  //       setData(masterData);
  //       if(nextkeyval != "" && nextkeyval != null)
  //       {
  //         setShowNext(false);
  //       }
  //       // if(masterData.length > 0)
  //       // {
  //       //    for(var i = 0; i < masterData.length; i++)
  //       //    {
  //       //     countdata = countdata + masterData[i].orders.length;
  //       //    }
  //       // }
  //       setTotalDataCount(totalDataCountcheck)
  //       binddatacount(totalDataCountcheck)
  //       // renderOrders();
  //       // renderOrdersformobile();
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //   } finally {
  //     setTimeout(function () {
  //       setIsLoading(false);
  //     }, 1000);
  //   }
  // }
  // function checkTime(i) {
  //   if (i < 10) {
  //     i = "0" + i;
  //   }
  //   return i;
  // }

  function handletextChange(event) {
    try {
      setTimeout(function () {
        let getsearchvalue = localStorage.getItem("sessiontxtsearchval");
        let textvalue;
        if (event !== undefined) {
          textvalue = event.target.value;
        } else if (
          getsearchvalue !== null &&
          getsearchvalue !== "" &&
          getsearchvalue !== undefined
        ) {
          document.getElementById("txtsearch").value = getsearchvalue;
          textvalue = getsearchvalue;
        } else {
          let txtsearch = document.getElementById("txtsearchm");
          if (txtsearch) {
            textvalue = document.getElementById("txtsearchm").value;
          }
        }
        if (textvalue !== "" && textvalue !== null && textvalue !== undefined) {
          if (textvalue.length > 3) {
            localStorage.setItem("sessiontxtsearchval", textvalue);
            setIsLoading(true);
            let sessionfilterurl = localStorage.getItem("sessionfilterurl");
            let currenturl;
            if (
              sessionfilterurl !== "" &&
              sessionfilterurl !== null &&
              sessionfilterurl !== undefined
            ) {
              currenturl = localStorage.getItem("sessionfilterurl");
            } else {
              currenturl = filterUrl;
            }
            axios
              .get(currenturl + "&SearchQuery=" + textvalue)
              .then(function (res) {
                setTimeout(function () {
                  setData(res.data);
                  setMasterData(res.data);
                  setIsLoading(false);
                }, 1000);
                setCurrentUser(res.data);
                setTotalDataCount(res.headers.totalrecords);
                setTotalDataCountcheck(res.headers.totalrecords);
                setPost(res);
                binddatacount(res.headers.totalrecords);
                // setIswestcoast(res.headers.iswestcoast);
                if (res.headers.nextkey !== null && res.headers.nextkey !== "") {
                  setShowNext(false);
                  setNextKeyval(res.headers.nextkey);
                } else {
                  setShowNext(true);
                }
              })
              .catch(function (err) {
                if (err.response.status === 401) {
                  window.location = "/Login?error=" + err.response.data.message;
                } else if (err.response.status === 403) {
                  window.location =
                    "/Login?error=" +
                    "Your token is expired, please login from bpc.";
                } else {
                  window.location = "/Error?error=" + err.message;
                }
              });
          }
        } else if (textvalue.length == 0) {
          localStorage.removeItem("sessiontxtsearchval");
          setIsLoading(true);
          axios
            .get(filterUrl)
            .then(function (res) {
              setTimeout(function () {
                setData(res.data);
                setMasterData(res.data);
                setIsLoading(false);
              }, 1000);
              setCurrentUser(res.data);
              setTotalDataCount(res.headers.totalrecords);
              setTotalDataCountcheck(res.headers.totalrecords);
              setPost(res);
              binddatacount(res.headers.totalrecords);
              //setIswestcoast(res.headers.iswestcoast);
              if (res.headers.nextkey !== null && res.headers.nextkey !== "") {
                setShowNext(false);
                setNextKeyval(res.headers.nextkey);
              } else {
                setShowNext(true);
              }
            })
            .catch(function (err) {
              if (err.response.status === 401) {
                window.location = "/Login?error=" + err.response.data.message;
              } else if (err.response.status === 403) {
                window.location =
                  "/Login?error=" +
                  "Your token is expired, please login from bpc.";
              } else {
                window.location = "/Error?error=" + err.message;
              }
            });
        }
        setShowPrev(true);
        localStorage.removeItem("sessionapplyfiltercount");
        localStorage.removeItem("sessionOrderPageKey");
        localStorage.removeItem("sessionOrderPagePrevKey");
      }, 1000);
    } catch (err) {
      console.log(err.message);
    }
  }

  function submitNextPage() {
    localStorage.removeItem("sessionOrderPageKey");
    localStorage.removeItem("sessionOrderPagePrevKey");
    localStorage.setItem("sessionOrderPageKey", nextkeyval);
    setStackCount(stackCount + 1);
    setShowPrev(false);
    //below session for check filter count.
    let getfiltercount = localStorage.getItem("sessionapplyfiltercount");
    if (getfiltercount == null) {
      getfiltercount = 0;
    }
    localStorage.setItem("sessionapplyfiltercount", getfiltercount + 1);
    try {
      let postUrl;
      let sessionfilterurl = localStorage.getItem("sessionfilterurl").toString();
      if (
        sessionfilterurl !== "" &&
        sessionfilterurl !== null &&
        sessionfilterurl !== undefined
      ) {
        postUrl = localStorage.getItem("sessionfilterurl");
      } else {
        postUrl = filterUrl;
      }
      let getsearchboxvalue = localStorage.getItem("sessiontxtsearchval");
     if (
          getsearchboxvalue !== null &&
          getsearchboxvalue !== "" &&
          getsearchboxvalue !== undefined
        ) {
            postUrl = postUrl + "&SearchQuery=" + getsearchboxvalue;
        }
      if (postUrl !== null && postUrl !== "" && postUrl !== undefined) {
        let aftersymbolurl = postUrl.substr(
          0,
          postUrl.lastIndexOf("&") + 8
        );
        let lastseven = aftersymbolurl.substr(aftersymbolurl.length - 7);
        if (lastseven == "nextkey") {
          postUrl = aftersymbolurl + "=" + nextkeyval;
        } else {
          postUrl += "&nextkey=" + nextkeyval;
        }
      } else {
        postUrl = url + "/api/orders?" + "nextkey=" + nextkeyval;
      }
      setIsLoading(true);
      axios
        .get(postUrl)
        .then(function (res) {
          setData(res.data);
          setMasterData(res.data);
          setCurrentUser(res.data);
          setPost(res);
          setTotalDataCount(res.headers.totalrecords);
          setTotalDataCountcheck(res.headers.totalrecords);
          // setIswestcoast(res.headers.iswestcoast);
          let currentstackvalue = stack;
          // setStack((stack) => stack.concat(nextkeyval));
          let newstack = currentstackvalue.concat(nextkeyval);
          setStack(newstack);
          let currentStack = stack;
          if (currentStack.length == 0) {
            localStorage.setItem("sessionOrderPagePrevKey", nextkeyval);
          } else {
            localStorage.setItem("sessionOrderPagePrevKey", newstack);
          }
          if (res.headers.nextkey !== null && res.headers.nextkey !== "") {
            setShowNext(false);
            setNextKeyval(res.headers.nextkey);
          } else {
            setShowNext(true);
          }
          binddatacount(res.headers.totalrecords);
        })
        .catch(function (err) {
          if (err.response.status === 401) {
            window.location = "/Login?error=" + err.response.data.message;
          } else if (err.response.status === 403) {
            window.location =
              "/Login?error=" + "Your token is expired, please login from bpc.";
          } else {
            window.location = "/Error?error=" + err.message;
          }
        });
    } catch (err) {
      console.log(err.message);
    } finally {
      setTimeout(function () {
        setIsLoading(false);
      }, 1000);
    }
  }
  function submitPrevPage() {
    try {
      if (stack !== null && stack !== "" && stack !== undefined) {
        let listlength = stack.length;
        if (listlength > 0) {
          let currentList = stack;
          currentList.pop();
          setStack(currentList);
          localStorage.removeItem("sessionOrderPageKey");
          localStorage.removeItem("sessionOrderPagePrevKey");
          localStorage.setItem("sessionOrderPageKey", currentList);
          localStorage.setItem("sessionOrderPagePrevKey", currentList);
          setStackCount(stackCount + 1);
          let currentLength = stack.length;
          let postUrl;
          try {
            let sessionfilterurl = localStorage.getItem("sessionfilterurl");
            if (
              sessionfilterurl !== "" &&
              sessionfilterurl !== null &&
              sessionfilterurl !== undefined
            ) {
              postUrl = localStorage.getItem("sessionfilterurl").toString();
            } else {
              postUrl = filterUrl;
            }
            if (
              postUrl !== null &&
              postUrl !== "" &&
              postUrl !== undefined &&
              currentLength > 0
            ) {
              let aftersymbol = postUrl.substr(0, postUrl.lastIndexOf("&") + 8);
              let lastseven = aftersymbol.substr(aftersymbol.length - 7);
              if (lastseven == "nextkey") {
                postUrl = aftersymbol + "=" + stack[currentLength - 1];
              } else {
                postUrl += "&nextkey=" + stack[currentLength - 1];
              }

              if (currentLength == 0) {
                setShowPrev(true);
              }
            } else {
              let aftersymbol = postUrl.substr(0, postUrl.lastIndexOf("&") + 8);
              let lastseven = aftersymbol.substr(aftersymbol.length - 7);
              if (lastseven == "nextkey") {
                let furl = postUrl.substr(0, postUrl.lastIndexOf("&"));
                postUrl = furl;
              } else {
                postUrl = filterUrl;
              }
              setShowPrev(true);
            }
            let getsearchboxvalue = localStorage.getItem("sessiontxtsearchval");
     if (
          getsearchboxvalue !== null &&
          getsearchboxvalue !== "" &&
          getsearchboxvalue !== undefined
        ) {
            postUrl = postUrl + "&SearchQuery=" + getsearchboxvalue;
        }
            setIsLoading(true);
            axios
              .get(postUrl)
              .then(function (res) {
                setData(res.data);
                setMasterData(res.data);
                setPost(res);
                setTotalDataCount(res.headers.totalrecords);
                setTotalDataCountcheck(res.headers.totalrecords);
                binddatacount(res.headers.totalrecords);
                //setIswestcoast(res.headers.iswestcoast);
                if (res.headers.nextkey !== null && res.headers.nextkey !== "") {
                  setShowNext(false);
                  setNextKeyval(res.headers.nextkey);
                } else {
                  setShowNext(true);
                }
              })
              .catch(function (err) {
                if (err.response.status === 401) {
                  window.location = "/Login?error=" + err.response.data.message;
                } else if (err.response.status === 403) {
                  window.location =
                    "/Login?error=" +
                    "Your token is expired, please login from bpc.";
                } else {
                  window.location = "/Error?error=" + err.message;
                }
              });
          } catch (err) {
            console.log(err.message);
          }
        }
      } else {
        setShowPrev(true);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setTimeout(function () {
        setIsLoading(false);
      }, 1000);
    }
  }

  return (
    <div>
      {currentUser ? (
        <div className="divOrder">
          <div className="row background">
            <div className="col-6">
              <p className="Orders">Orders</p>
              <div id="divsetdestname" className="chipmargin"></div>
            </div>
            <div className="col-3 desktop">
              <input
                type="text"
                className="form-control has-search"
                placeholder="Type at least 4 chars to search"
                minLength={3}
                onChange={handletextChange}
                id="txtsearch"
              />
            </div>
            <div className="col divFilterbtnpadding desktop">
              {/* <button
                type="button"
                className="btn btn-outline-success btn-sm btnmarginright"
                onClick={() => setShowColumnFilter(true)}
              >
                <Icon.Gear /> Setting
              </button> */}
              <button
                type="button"
                className="btn btn-outline-success btn-sm btnmarginright"
                onClick={() => setShowmodal(true)}
              >
                <Icon.ClockHistory /> History
              </button>
              <button
                type="button"
                id="btnFilter"
                className="btn btn-outline-success btn-sm"
                onClick={() => setShow(true)}
              >
                {" "}
                <Icon.Funnel /> Filter
                <span id="countFilter" aria-hidden="true"></span>
              </button>
            </div>
            <div className="col divFilterbtnpaddingm mobile">
              <Icon.ClockHistory
                className="mobileHistoryicon"
                onClick={() => setShowmodal(true)}
              />{" "}
              <Icon.Funnel
                className="mobilefiltericon"
                onClick={() => setShow(true)}
              />
            </div>
          </div>
          <div className="divOrdersTable desktop">
            {isLoading && (
              <div className="loader">
                <Spinner color="white" />
              </div>
            )}
            <table className="table" id="tblOrders">
              <thead className="bgcolor">
                <tr>
                  <th className="tdbold DestHead">
                    <span className="box bgcolor"></span> <Icon.GeoAlt />{" "}
                    {/* {isWestcoast === "true" ? "Site Address" : "Destination"} */}
                    Destination
                  </th>
                  {/* {isWestcoast === "true" ? (
                    <th className="tdwidth SiteNumberHead">
                      <Icon.Truck /> Site Number
                    </th>
                  ) : (
                    ""
                  )} */}
                  <th className="tdwidth EtaHead">
                    <Icon.Clock /> ETA
                  </th>
                  <th className="CarrierHead">
                    <Icon.Truck /> Carrier
                  </th>
                  <th className="SiteNameHead">
                    <Icon.GeoAlt /> Site Name
                  </th>
                  {isWestcoast === "true" ? (
                    <th className="ArcoHead">
                      <Icon.GeoAlt /> Arco#
                    </th>
                  ) : (
                    ""
                  )}
                  <th className="tdwidthorder OrderHead">
                    {" "}
                    <Icon.CardList /> Order
                  </th>
                  <th className="tdwidthstatus- StatusHead">
                    <Icon.Circle /> Status
                  </th>
                  <th className="tdwidth UpdatedHead">
                    <Icon.ClockHistory /> Updated
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="bgtbodycolor">
                {post ? renderOrders() : null}
              </tbody>
            </table>
          </div>
          <div class="desktop">
            <div className="rowCenter paddingside">
              <div className="row">
                {!showPrev ? (
                  <div className="col-md-1">
                    <button
                      class="btn btn-success btn-sm"
                      onClick={() => submitPrevPage()}
                      disabled={showPrev}
                    >
                      prev
                    </button>
                  </div>
                ) : (
                  <div className="col-md-1"></div>
                )}
                <div className="col-md-10 clrGreen">
                  {dataCount} / {totalDataCount}
                </div>
                {!showNext ? (
                  <div className="col-md-1">
                    <button
                      class="btn btn-success btn-sm"
                      onClick={() => submitNextPage()}
                      disabled={showNext}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div className="col-md-1"></div>
                )}
              </div>
            </div>
          </div>
          <div className="row mobile">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Type at least 4 chars to Search"
                minLength={3}
                id="txtsearchm"
                onChange={handletextChange}
              />
            </div>
          </div>
          <div className="divOrdersTablem mobile">
            {isLoading && (
              <div className="loader">
                <Spinner color="white" />
              </div>
            )}
            {post ? renderOrdersformobile() : null}
          </div>
          <div className="mobile">
            <div className="rowCenter paddingside">
              <div className="row">
                <div className="col-md-10 clrGreen card-body">
                  {!showPrev ? (
                    <button
                      class="btn btn-success btn-sm floatleft"
                      onClick={() => submitPrevPage()}
                      disabled={showPrev}
                    >
                      prev
                    </button>
                  ) : (
                    <div className="col"></div>
                  )}
                  {dataCount} / {totalDataCount}
                  {!showNext ? (
                    <button
                      class="btn btn-success btn-sm floatright"
                      onClick={() => submitNextPage()}
                      disabled={showNext}
                    >
                      Next
                    </button>
                  ) : (
                    <div className="col"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            onClose={() => {
              setShow(false);
              bindSelectedItems();
            }}
            show={show}
          ></Modal>
          <HistoryModal
            onClose={() => {
              setShowmodal(false);
              bindSelectedItems();
            }}
            modalshow={modalshow}
          ></HistoryModal>
          <ColumnFilter
            onClose={() => {
              setShowColumnFilter(false);
            }}
            ColumnFiltershow={columnFiltershow}
            isWestcoast={isWestcoast}
          ></ColumnFilter>
        </div>
      ) : (
        <div className="loader">
          <Spinner color="white" />
        </div>
      )}
    </div>
  );
};
export default OrderTable;
